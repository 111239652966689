import React from "react"
import Helmet from "react-helmet"
import Layout from "../../components/layout"
import { Link } from "gatsby"
import { Container, Row, Col, Button } from "react-bootstrap"
import arrestPhoto from "../../images/Birmingham-Police-arrest-suspect-in-officer-involved-shooting.jpg"
import ContactForm from "../../components/contact-us-form"
import PracticeAreas from "../../components/practice-areas"
// dui attorneys birmingham al
// best criminal defense attorneys in birmingham al
export default () => (
  <Layout style={{ color: `teal` }}>
    <Helmet
      title="Birmingham Criminal Defense Lawyers | RWK Attorneys"
      defer={false}
    />

    <section
      style={{
        backgroundImage:
          "linear-gradient(90deg, rgba(255,255,255,1) 20%, rgba(255,255,255,0)), url('/cover.webp')",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div
        className="container text-center text-md-left d-flex justify-content-start align-items-center"
        style={{ minHeight: "40vh" }}
      >
        <div className="row">
          <div className="col">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb pb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li class="breadcrumb-item">
                  <Link to="/practice-areas">Practice Areas</Link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Criminal Defense
                </li>
              </ol>
            </nav>
            <h1>
              Experienced <span className="text-primary">Criminal Defense</span>
              <br /> Representation
            </h1>
            {/* <p className="lead">With over 75 years of combined legal experience serving residents across the State of Alabama, particularly in Jefferson, St. Clair, Blount and Shelby counties.</p> */}
            {/* <p className="lead">Serving residents of Alabama<br /> with over 75 years of<br /> combined legal experience</p> */}
            {/* <p><Link className="btn btn-primary" to="/practice-areas/#pa">Learn More</Link></p> */}
          </div>
        </div>
      </div>
    </section>

    <Container className="my-5">
      <Row>
        <Col md="3">
          <PracticeAreas />

          <ContactForm />
        </Col>
        <Col className="mt-5 mt-sm-0">
          <h2 className="text-uppercase">
            Experienced Criminal Defense Representation
          </h2>
          <p className="lead">
            You need an experienced criminal defense attorney to protect your
            rights and freedom when you have been charged with a serious
            criminal offense.
          </p>
          {/* <p className="lead">
            When you are charged with a crime, you risk jail or prison, but you
            may also be faced with loss of a job, CDL license or driver’s
            license. You need an attorney on your side that will help protect
            your rights.
          </p> */}

          <p>
            Our attorneys are experienced trial attorneys and represent
            individuals in a wide spectrum of legal matters from capitol murder
            charges to those charged with misdemeanor crimes such as
            shoplifting. We have had great success in obtaining acquittals,
            reduced charges, and getting charges dropped{" "}
            <span style={{ textDecoration: "underline" }}>completely</span>. We
            represent individuals in various charges in municipal courts such
            as:
          </p>

          <ul>
            <li>murder</li>
            <li>assault</li>
            <li>sex-related crimes</li>
            <li>failure to register</li>
            <li>theft</li>
            <li>robbery</li>
            <li>drug offenses</li>
            <li>trafficking</li>
            <li>DUI/BUI/WUI</li>
          </ul>

          <p>
            Additionally, we represent clients in administrative hearings and
            professional licensing issues, such as driver’s license appeals
            related to DUIs. We also assist individuals with expunging their
            criminal records so that they can obtain a fresh start.
          </p>

          <p>
            If you are under investigation or if you have been charged with a
            crime, our lawyers can provide immediate and aggressive
            representation. Our firm has achieved outstanding results at every
            stage of the criminal process, including preliminary hearings,
            pretrial hearings, motion hearings and at trial. Remember, facing a
            criminal charge of any type usually has very serious consequences.
            Let us protect your rights and assist you in minimizing the risk of
            penalties.
          </p>
          <p>
            If you have been charged with a crime or would like to speak with an
            attorney about expunging your record, please contact us to discuss
            your circumstances.
          </p>

          <section
            className="my-5"
            style={{
              backgroundImage:
                "linear-gradient(30deg, rgba(0,0,0,1) 40%, rgba(0,0,0,0)), url('" +
                arrestPhoto +
                "')",
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: "30vh",
            }}
          >
            <Container style={{ height: "100%" }}>
              <Row className="align-items-center" style={{ height: "100%" }}>
                <Col className="">
                  <h2 className="text-light">
                    Arrested?
                    <br />
                    <small>Call us immediately</small>
                  </h2>
                  <p className="lead d-none d-md-block">
                    <a href="tel://12058332589">(205) 833-2589</a>
                  </p>
                  <Button
                    className="d-block d-md-none"
                    href="tel://12058332589"
                  >
                    Call now
                  </Button>
                </Col>
              </Row>
            </Container>
          </section>
        </Col>
      </Row>
    </Container>

    <Container></Container>
  </Layout>
)
